import {Modal} from "antd";
import {WifiOutlined} from "@ant-design/icons";
import {t} from "i18next";
import {Trans} from "react-i18next";
import React from "react";

/**
 * Notification when the api server cannot be joined
 */
let serverNotAvailableModalOpen = false;
export const displayServerNotAvailableErrorModal = () => {
  if (!serverNotAvailableModalOpen) {
    serverNotAvailableModalOpen = true;
    Modal.error({
      icon: <WifiOutlined />,
      title: t("common:serverUnavailableModal.title"),
      content: <Trans ns="common" i18nKey="serverUnavailableModal.content" />,
      okText: t("common:serverUnavailableModal.tryAgain"),
      onOk: () => (serverNotAvailableModalOpen = false),
      onCancel: () => (serverNotAvailableModalOpen = false),
      closable: true,
      maskClosable: true,
    });
  }
};
