import dayjs from "dayjs";

const getSlotsEnd = (slots) => {
  if (slots?.length > 1) {
    return dayjs.max(...slots.map((s) => dayjs(s.end))).format();
  } else {
    return slots[0]?.end ? dayjs(slots[0].end) : undefined;
  }
};

const getSlotsStart = (slots) => {
  if (slots?.length > 1) {
    return dayjs.min(...slots.map((s) => dayjs(s.start))).format();
  } else {
    return slots[0]?.start ? dayjs(slots[0].start) : undefined;
  }
};

/**
 * Returns the earliest start and latest end of all slots given
 */
export const getSlotsStartAndEnd = (slots, defaultStart) => {
  return {start: getSlotsStart(slots) || defaultStart, end: getSlotsEnd(slots)};
};

export const slotEndIsBeforeBeginning = (slot) =>
  dayjs(slot.start).toDate() > dayjs(slot.end).toDate();

export const hasSameStartAndEnd = (slotA, slotB) =>
  dayjs(slotA.start).isSame(slotB.start) && dayjs(slotA.end).isSame(slotB.end);

export const slotOverlapsAnOtherOne = (slot, availabilitySlots, ignoreThisSlot = undefined) => {
  if (!availabilitySlots) return false;

  for (let existingSlot of availabilitySlots) {
    if (ignoreThisSlot && hasSameStartAndEnd(existingSlot, ignoreThisSlot)) {
      continue;
    }

    // Check if the slot overlaps an other slot
    if (
      dayjs(slot.start).toDate() < dayjs(existingSlot.end).toDate() &&
      dayjs(slot.end).toDate() > dayjs(existingSlot.start).toDate()
    ) {
      return true;
    }
  }

  return false;
};
