import {t} from "i18next";
import {fetchWithMessages} from "./fetchWithMessages";

/**
 * Persists an entity in the backend
 *
 * @param endpoint the endpoint to persist at
 * @param entity the entity object to persist
 * @param projectId the id of the project the element belongs to
 * @param addEntityAction what to do once the element has been created in the backend and returned to the frontend
 * @param updateEntityAction what to do once the element has been updated in the backend and returned to the frontend
 * @param showSuccessNotification show the success message if the save has succeded
 * @returns {Promise<string>} returns the slug (if it has any) or the id of the persisted element
 */
export const persistEntityInBackend = async (
  endpoint,
  entity,
  projectId,
  addEntityAction,
  updateEntityAction,
  showSuccessNotification = true
): Promise<string> => {
  const baseUrl = projectId ? `projects/${projectId}/${endpoint}` : endpoint;
  if (entity._id === "new") {
    const {_id, ...entityWithoutId} = entity; // Remove the "new" id before sending the request
    const data = await fetchWithMessages(
      baseUrl,
      {method: "POST", body: entityWithoutId},
      {200: showSuccessNotification ? t("common:creationSuccessful") : undefined},
      undefined,
      showSuccessNotification
    );
    await addEntityAction(data);
    return data?.slug || data?._id;
  } else {
    const data = await fetchWithMessages(
      `${baseUrl}/${entity._id}`,
      {method: "PATCH", body: entity},
      {
        200: showSuccessNotification ? t("common:modificationsSaved") : undefined,
        401: {type: "error", message: true},
        405: {type: "warning", message: true},
      },
      undefined,
      showSuccessNotification
    );
    await updateEntityAction(data);
    return data?.slug || data?._id;
  }
};
