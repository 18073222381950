import {sessionsActions} from "../../features/sessions";
import {activitiesActions} from "../../features/activities";
import {placesActions} from "../../features/places";
import {stewardsActions} from "../../features/stewards";
import {teamsActions} from "../../features/teams";
import {registrationsActions} from "../../features/registrations";

export const loadAllEntities = async (dispatch) =>
  await Promise.all([
    dispatch(sessionsActions.loadList()),
    dispatch(sessionsActions.loadCategoriesList()),
    dispatch(activitiesActions.loadList()),
    dispatch(placesActions.loadList()),
    dispatch(stewardsActions.loadList()),
    dispatch(teamsActions.loadList()),
    dispatch(registrationsActions.loadList()),
  ]);
