// @ts-ignore
import logoColors from "./images/logo-colors.svg";
// @ts-ignore
import logoWhiteWithWhiteText from "./images/logo-white-with-white-text.svg";
// @ts-ignore
import logoColorsWithBlueText from "./images/logo-colors-with-blue-text.svg";
// @ts-ignore
import {WEBSITE_URL} from "./../style/sharedConfig";

// Base default spinner logo to take
export const defaultSpinnerLogo = logoColors;

// Logo to display in the AuthPage
export const connectionPageLogo = logoWhiteWithWhiteText;

// Logo to display in a basic text environment
export const textLogo = logoColorsWithBlueText;

export const connectionPageBackground =
  "linear-gradient(135deg, var(--noe-accent-1-90), 40%, var(--noe-accent-2))";

// Instance name
export const instanceName = process.env.REACT_APP_INSTANCE_NAME || "NOÉ";

// URLs
export const URLS = {
  ORGA_FRONT: process.env.REACT_APP_ORGA_FRONT_URL as string,
  INSCRIPTION_FRONT: process.env.REACT_APP_INSCRIPTION_FRONT_URL as string,
  API: process.env.REACT_APP_API_URL as string,
  WEBSITE: WEBSITE_URL as string,

  CURRENT: process.env.REACT_APP_INSCRIPTION_FRONT_URL as string,
} as const;
