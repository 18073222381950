import {theme as antdTheme} from "antd";

const {darkAlgorithm} = antdTheme;

const darkThemeBase = {
  token: {
    colorPrimary: "#4479d7",
    colorLink: "#4479d7",
    borderRadius: 10,
    colorBgContainer: "#090909",
    colorBgElevated: "#1b1b1b",
    colorBgLayout: "#181818",
    colorBorder: "#4b4b4b",
    colorBorderSecondary: "#3d3d3d",
    wireframe: false,
    colorInfo: "#4479d7",
  },
  components: {
    Segmented: {
      itemSelectedBg: "#3a3a3a",
    },
  },
  algorithm: darkAlgorithm,
};

const lightThemeBase = {
  token: {
    colorPrimary: "#3775e1",
    colorLink: "#3775e1",
    colorTextBase: "#000a2e",
    borderRadius: 10,
    colorBgContainer: "#ffffff",
    colorBgElevated: "#fafafa",
    colorBgLayout: "#f8f8f8",
    colorBorder: "#d0d0d0",
    colorBorderSecondary: "#e3e3e3",
    wireframe: false,
  },
  components: {
    Collapse: {
      colorFillAlter: "#f5f5f5",
    },
  },
};

const defaultComponentsTheme = {
  Dropdown: {
    borderRadiusLG: 14,
  },
  Button: {
    borderRadius: 10,
  },
  Tag: {
    borderRadiusSM: 8,
  },
  Pagination: {
    borderRadiusLG: 12,
    borderRadius: 10,
  },
  Card: {
    fontWeightStrong: 500,
  },
  Table: {
    borderRadiusLG: 0,
  },
  Modal: {
    fontWeightStrong: 500,
    wireframe: true,
  },
  Drawer: {
    fontWeightStrong: 500,
  },
};

const buildTheme = (additional) => ({
  ...additional,
  components: {
    ...defaultComponentsTheme,
    ...additional.components,
  },
});

export const LIGHT_THEME = buildTheme(lightThemeBase);
export const DARK_THEME = buildTheme(darkThemeBase);
