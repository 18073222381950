import React from "react";
import {Link} from "react-router-dom";
import {replaceInText, replaceInTextWithJSX} from "./stringUtilities";
import {t} from "i18next";

// This function is duplicated in api/src/entities/session.model.ts
export const getMaxParticipantsBasedOnPlaces = (session) =>
  session.places?.length > 0
    ? session?.places?.reduce((acc, place) => acc + (place.maxNumberOfParticipants ?? Infinity), 0)
    : undefined;

export const getVolunteeringCoefficient = (session, activities?) =>
  session.volunteeringCoefficient || session.volunteeringCoefficient === 0
    ? session.volunteeringCoefficient
    : (activities?.find((activity) => activity._id === session.activity._id) || session.activity)
        .volunteeringCoefficient || 0;

export const getSessionName = (session, sessionNameTemplate, simpleMode = true, path?) => {
  const activityNameNode = simpleMode ? (
    session.activity.name
  ) : (
    <Link to={`${path}/activities/${session.activity?._id}`}>{session.activity?.name}</Link>
  );
  const sessionName = session.name;
  const template = sessionNameTemplate || t("projects:schema.sessionNameTemplate.defaultValue");

  if (sessionName?.length > 0) {
    const replacementFunction = simpleMode ? replaceInText : replaceInTextWithJSX;
    return replacementFunction(template, {sessionName, activityName: activityNameNode});
  } else {
    return activityNameNode;
  }
};

export const getFullSessionName = (session, sessionNameTemplate, teams?) => {
  const sessionName = getSessionName(session, sessionNameTemplate, true);
  const teamName =
    teams?.find((t) => t._id === (session.team?._id || session.team))?.name || session.team?.name;

  return sessionName + (teamName ? ` (${teamName})` : "");
};
