import {Form, FormItemProps as AntdFormItemProps} from "antd";
import React from "react";
import {ReactNode} from "react";
import {useTranslation} from "react-i18next";

type PickedFormItemProps = "name" | "label" | "rules" | "tooltip" | "normalize" | "noStyle";

export type FormItemProps<T> = Pick<AntdFormItemProps, PickedFormItemProps> & {
  i18nNs?: string;
  icon?: any;
  placeholder?: string;
  description?: AntdFormItemProps["extra"];
  formItemProps?: AntdFormItemProps;
  children?: ReactNode;
  required?: boolean;
  inputProps?: T;
} & Omit<T, PickedFormItemProps>;

export function FormItem<T>({
  name,
  i18nNs,
  icon,
  label,
  rules,
  tooltip,
  normalize,
  noStyle,
  required,
  description,
  formItemProps,
  children,
  inputProps,
  ...otherInputProps
}: FormItemProps<T>) {
  const {t, i18n} = useTranslation();
  if (i18nNs) {
    const keyRoot = `${i18nNs}:schema.${name}`;

    const assignTextForKey = <T,>(defaultValue: T, propName: string) => {
      const i18nKey = `${keyRoot}.${propName}`;
      return defaultValue || (i18n.exists(i18nKey) ? t(i18nKey) : undefined);
    };

    // Fallback on i18n translations if no label/placeholder/tooltip given
    label = assignTextForKey(label, "label");
    otherInputProps.placeholder = assignTextForKey(otherInputProps.placeholder, "placeholder");
    tooltip = assignTextForKey(tooltip, "tooltip");
  }

  // Shortcut for required rule
  if (required) (rules ||= []).unshift({required: true});

  return (
    <Form.Item
      label={
        icon ? (
          <>
            <span style={{marginRight: 8, opacity: 0.8}}>{icon}</span> {label}
          </>
        ) : (
          label
        )
      }
      name={name}
      tooltip={tooltip}
      rules={rules}
      noStyle={noStyle}
      normalize={normalize}
      {...formItemProps}>
      {children &&
        React.cloneElement(children as React.DetailedReactHTMLElement<any, HTMLElement>, {
          ...otherInputProps,
          ...inputProps,
        })}
    </Form.Item>
  );
}
