import {useState} from "react";

export const getLocalStorage = (key, defaultValue) => {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : defaultValue;
  } catch (error) {
    // If error also return initialValue
    console.log(error);
    return defaultValue;
  }
};

export const setLocalStorage = (key, value) => {
  try {
    // Save to local storage
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    // A more advanced implementation would handle the error case
    console.log(error);
  }
};

export const useLocalStorageState = (key, defaultValue) => {
  const [value, setValue] = useState(getLocalStorage(key, defaultValue));

  const setLocalStorageValue = (val) => {
    setLocalStorage(key, val);
    setValue(val);
  };

  return [value, setLocalStorageValue];
};

export const createLocalStorageGetSetter = (key, defaultValue) => {
  const getSetter = () => getLocalStorage(key, defaultValue);
  getSetter.set = (val) => setLocalStorage(key, val);
  return getSetter;
};
