import {default as SockJS} from "sockjs-client";
import {useEffect} from "react";
import {useOnlineStatus} from "../../common/hooks/useOnlineStatus";
import {generateRandomId} from "../stringUtilities";

export let lastWebsocketConnectionId: string;

const generateSessionId = () => {
  const sessionId = generateRandomId();
  lastWebsocketConnectionId = sessionId;
  return sessionId;
};

type OnMessage = (message: any) => void;
type WsKey = {type: string; id: string};

export const createWebSocketConnection = (key: WsKey, onMessage: OnMessage) => {
  const websocketUrl = `${process.env.REACT_APP_API_WEBSOCKET_URL}/ws`;

  // Generate a pseudo random Id to identify the connection
  const ws = new SockJS(websocketUrl, undefined, {sessionId: generateSessionId});
  ws.onmessage = ({data}) => onMessage(JSON.parse(data));
  ws.onopen = () => ws.send(JSON.stringify(key));
  return ws;
};

export const useWebSocketConnection = (activate = false, key: WsKey, onMessage: OnMessage) => {
  const online = useOnlineStatus();

  useEffect(() => {
    if (activate && online) {
      try {
        const ws = createWebSocketConnection(key, onMessage);
        return () => ws.close();
      } catch (e) {
        console.error("Websocket concurrent editing failed.", e);
      }
    }
  }, [onMessage, activate, online, key]);
};
