import type {FormItemProps} from "./FormItem";
import {FormItem} from "./FormItem";
import {Switch, SwitchProps} from "antd";
import React from "react";

export const SwitchInput = (props: FormItemProps<SwitchProps>) => (
  <FormItem {...{...props, formItemProps: {valuePropName: "checked", ...props.formItemProps}}}>
    <Switch />
  </FormItem>
);

export const SwitchInputInline = ({
  label,
  formItemProps,
  style,
  ...props
}: FormItemProps<SwitchProps>) => (
  <div className={"containerH"} style={{alignItems: "center", gap: 8, ...style}}>
    <SwitchInput noStyle style={{margin: 0}} {...props} />
    {label}
  </div>
);
