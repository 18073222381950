// https://gist.github.com/patrickcze/b0701f4aa9dbaa7e815a0af62c41541e#file-useonlinestatus-tsx
import React, {useContext, useEffect, useState} from "react";
import {URLS} from "../../app/configuration";

const PING_RESOURCE = `${URLS.API}/assets/check-online.html`;
const TIMEOUT_TIME_MS = 3000;
const onlinePollingInterval = 10000;

interface TimeoutFunction {
  <T>(time: number, promise: Promise<T>): Promise<T>;
}

const timeout: TimeoutFunction = (time, promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error("Request timed out."));
    }, time);
    promise.then(resolve, reject);
  });
};

const checkOnlineStatus = async () => {
  const controller = new AbortController();
  const {signal} = controller;

  // If the browser has no network connection return offline
  if (!navigator.onLine) return navigator.onLine;

  //
  try {
    await timeout(TIMEOUT_TIME_MS, fetch(PING_RESOURCE, {method: "GET", signal}));
    return true;
  } catch (error) {
    // This can be because of request timed out
    // so we abort the request for any case
    controller.abort();
  }
  return false;
};

const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [onlineStatus, setOnlineStatus] = useState(true);

  const checkStatus = async () => {
    const online = await checkOnlineStatus();
    setOnlineStatus(online);
  };

  useEffect(() => {
    window.addEventListener("offline", () => {
      setOnlineStatus(false);
    });

    // Add polling incase of slow connection
    const id = setInterval(() => {
      checkStatus();
    }, onlinePollingInterval);

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });

      clearInterval(id);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>{children}</OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const online = useContext(OnlineStatusContext);
  return online;
};
