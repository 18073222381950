import {useMemo} from "react";

function debounce(func, wait = 166) {
  let timeout;

  function debounced(...args) {
    // eslint-disable-next-line consistent-this
    const that = this;
    const later = () => {
      func.apply(that, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

// use the useMemo hooks so the debounce function is defined once. Otherwise, it is defined at each rerender and each event
// fires a new debounce function and nothing works
export const useDebounce = (action, wait = 500, deps = []) =>
  useMemo(() => debounce(action, wait), deps);
