import {Button} from "antd";
import React from "react";
import {ArgsProps} from "antd/es/notification/interface";
import {AppMethods} from "../common/hooks/useThemedAppMethods";

/**
 * Display a notification for the user
 * @param type notif type : success, warning, etc
 * @param key the identificator
 * @param buttonText if there is a button, the text of the button
 * @param onClickButton if there is a button, the action on click of the button
 * @param props additionnal notification props
 */
export const displayNotification = (
  type,
  key,
  {
    buttonText,
    onClickButton,
    ...props
  }: ArgsProps & {buttonText?: React.Node, onClickButton?: () => void}
) =>
  AppMethods.notification[type]({
    key: key,

    btn: buttonText && onClickButton && (
      <Button
        type="primary"
        onClick={() => {
          onClickButton();
          AppMethods.notification.destroy(key);
        }}>
        {buttonText}
      </Button>
    ),
    duration: 0, // by default, notif will stay forever
    placement: "bottomRight",
    ...props, // Override with props if given
  });
