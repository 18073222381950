import {fetchWithMessages} from "./fetchWithMessages";

export const loadEntityFromBackend = async (
  endpoint,
  entityId,
  projectId,
  existingEntity,
  changeNewEntityEditingAction,
  changeExistingEntityEditingAction,
  options
) => {
  const {notFoundAction, silentFailIfNotFound} = {
    notFoundAction: undefined,
    silentFailIfNotFound: false,
    ...options,
  };

  if (!existingEntity || existingEntity._id !== entityId) {
    if (entityId === "new") {
      changeNewEntityEditingAction();
    } else {
      try {
        const data = await fetchWithMessages(
          projectId ? `projects/${projectId}/${endpoint}/${entityId}` : `${endpoint}/${entityId}`,
          {method: "GET"},
          silentFailIfNotFound ? {404: null} : undefined
        );
        await changeExistingEntityEditingAction(data);
        return data?._id;
      } catch {
        notFoundAction && (await notFoundAction());
      }
    }
  }
};
