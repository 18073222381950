import i18next from "i18next";
import {initReactI18next, useTranslation} from "react-i18next";
import BrowserLanguageDetector from "i18next-browser-languagedetector";
import resources from "../locales";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import {useSelector} from "react-redux";
import {usersSelectors} from "../../features/users";
import {useEffect} from "react";
import utc from "dayjs/plugin/utc";
import minMax from "dayjs/plugin/minMax";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(LocalizedFormat);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);

i18next
  .use(BrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS: "common",
    fallbackNS: "common",
    interpolation: {escapeValue: false},
    fallbackLng: "fr",
  });

// Pre-select with the resolved language (meaning "fr" instead of "fr-FR")
dayjs.locale(i18next.resolvedLanguage);

export const useUserLocale = () => {
  const {i18n} = useTranslation();
  const user = useSelector(usersSelectors.selectEditing);

  useEffect(() => {
    // take the locale either from the user or from the i18n object.
    // Always take the resolved language as the main language
    // (for ex is language is "fr_FR", always force "fr" as the language)
    const newLocale = user.locale ? user.locale : i18n.resolvedLanguage;
    if (i18n.language !== newLocale) {
      i18n.changeLanguage(newLocale);
      dayjs.locale(newLocale);
    }
  }, [i18n, user?.locale]);
};
