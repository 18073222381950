import React, {useContext, useEffect, useState} from "react";
import {useDebounce} from "./useDebounce";

interface WindowDimensions {
  isMobileView: boolean;
  windowWidth: number;
  windowHeight: number;
}

const getWindowDimensions = (): WindowDimensions => {
  const {innerWidth: windowWidth, innerHeight: windowHeight} = window;
  return {isMobileView: windowWidth < 768, windowWidth, windowHeight};
};

const WindowDimensionsContext = React.createContext<WindowDimensions>(getWindowDimensions());

export const WindowDimensionsProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(getWindowDimensions());

  const debouncedSetDimensions = useDebounce(
    (dimensions: WindowDimensions) => setWindowDimensions(dimensions),
    100
  );

  useEffect(() => {
    function handleResize() {
      debouncedSetDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <WindowDimensionsContext.Provider value={windowDimensions}>
      {children}
    </WindowDimensionsContext.Provider>
  );
};

export const useWindowDimensions = (): WindowDimensions => useContext(WindowDimensionsContext);
