import {normalize} from "./stringUtilities";

export type SearchInFieldsValue<Item> = Array<keyof Item> | ((item: Item) => Array<string>);

const matchesSearchValueWords = <Item = any>(
  item: Item,
  normalizedSearchValueWords: Array<string>,
  searchInFields: SearchInFieldsValue<Item>
): boolean => {
  // For every word in the user search...

  // If searchInFields fields are given as an array, then pick the fields in the object.
  // Otherwise, if searchInFields is a function that picks values by itself, take its return value
  const potentialMatches = Array.isArray(searchInFields)
    ? (searchInFields.map((field) => item[field]) as Array<string>)
    : searchInFields(item);

  // Make sure that for every search word given, there is a match in our potential matches
  return normalizedSearchValueWords.every((word) =>
    potentialMatches.find(
      (fieldValue) => fieldValue?.length && normalize(fieldValue).includes(word)
    )
  );
};

/**
 * Searches for items in a list based on a search value and specified fields.
 * @param searchValue - The search value to match against items in the list.
 * @param list - The array of items to search within.
 * @param searchInFields - The fields to search within for each item, or a custom function to extract search strings from items.
 * @returns The filtered array of items that match the search value in the specified fields.
 */
export const searchInObjectsList = <Item>(
  searchValue: string,
  list: Array<Item>,
  searchInFields: SearchInFieldsValue<Item>
): Array<Item> | undefined => {
  if (!list) return;
  if (!searchValue || searchValue.length === 0) return list;

  // Normalize search value for comparison
  const normalizedSearchValueWords = normalize(searchValue).split(" ");

  // Filter elements that match search value
  return list.filter((item) =>
    matchesSearchValueWords(item, normalizedSearchValueWords, searchInFields)
  );
};
