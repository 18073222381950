import {fetchWithMessages} from "./fetchWithMessages";

export const loadListFromBackend = async (
  endpoint,
  projectId,
  initializedProjectId,
  initContextAction,
  initEntityAction,
  showLoading = true,
  navigateIfFail,
  notFoundAction
) => {
  if (!initializedProjectId || initializedProjectId !== projectId) {
    initContextAction && initContextAction();

    try {
      const data = await fetchWithMessages(
        projectId ? `projects/${projectId}/${endpoint}` : endpoint,
        {
          method: "GET",
        },
        undefined,
        undefined,
        showLoading
      );

      initEntityAction(data);
    } catch (e) {
      if (notFoundAction) {
        notFoundAction();
      } else {
        throw e;
      }
    }
  }
};
