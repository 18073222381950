import {lazy} from "react";

// A function to retry loading a chunk to avoid ChunkLoadError for out of date code
// https://www.codemzy.com/blog/fix-chunkloaderror-react
// https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406

export const componentLoader = (componentImportFn, attemptsLeft = 2) =>
  new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-component-load-refreshed`) || "false"
    );

    // Try to import the component
    componentImportFn()
      .then((component) => {
        // Clean sessionStorage and load component if success
        hasRefreshed && window.sessionStorage.removeItem(`retry-component-load-refreshed`); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (attemptsLeft > 0) {
          // If there are some attempts left, call again the load function one second later to try one more time.
          // Reduce the number of attempts left
          setTimeout(() => {
            componentLoader(componentImportFn, attemptsLeft - 1).then(resolve, reject);
          }, 1500);
        } else if (!hasRefreshed) {
          // Else if there are no more attempts left, just for-ce refresh the page
          window.sessionStorage.setItem(`retry-component-load-refreshed`, "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        } else {
          reject(error); // Default error behaviour as already tried refresh
        }
      });
  });

export const lazyWithRetry = (componentImportFn) => lazy(() => componentLoader(componentImportFn));
