import React, {Suspense} from "react";
import {defaultSpinnerLogo} from "../../app/configuration";
import {ReactNode} from "react";

export type PendingProps = {
  logo?: ReactNode,
  className?: string,
  minHeight?: CSSStyleDeclaration["minHeight"],
  animationDelay?: CSSStyleDeclaration["animationDelay"],
  animationType?: "spin" | "breathe",
  noFadeIn?: boolean,
};

/**
 * Default NOÉ pending animation
 * @param spinnerLogo
 * @param className
 * @param minHeight
 * @param animationDelay if not set, no delay
 * @param noFadeIn if we want to display directly without fadein
 */
export const Pending = ({
  logo = <img style={{width: 130, height: 130}} src={defaultSpinnerLogo} alt={"spinner"} />,
  className,
  minHeight = "100vh",
  animationDelay,
  animationType = "spin",
  noFadeIn = false,
}: PendingProps) => (
  <div
    className={`containerH ${noFadeIn ? "" : "fade-in"} ${className}`}
    style={{justifyContent: "center", overflow: "visible", minHeight, animationDelay}}>
    <div className="containerV" style={{justifyContent: "center", overflow: "visible"}}>
      <div className={animationType}>{logo}</div>
    </div>
  </div>
);

/**
 * React suspense with the NOE animation and smooth appear included
 * @param children the component to be displayed
 * @param minHeight see PendingProps
 * @param animationDelay see PendingProps
 * @param className see PendingProps
 * @param noFadeIn
 */
export const PendingSuspense = ({
  children,
  minHeight = 300,
  animationDelay = "400ms",
  className,
  noFadeIn = false,
}: {
  children: ReactNode,
} & PendingProps) => (
  <Suspense
    fallback={
      <Pending minHeight={minHeight} animationDelay={animationDelay} className={className} />
    }>
    <div className={noFadeIn ? undefined : "fade-in"}>{children}</div>
  </Suspense>
);
